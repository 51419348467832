import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { capitalize } from '@/filters/string';

@Component({
  components: {
  },
})
export default class NavbarLinks extends Vue {
  @StateClass assets!: State['assets'];

  get asset(): Asset {
    return this.assets[0];
  }

  capitalize = capitalize;

  get lang(): string {
    return this.$route.params.lang;
  }
}
