import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State as StateClass } from 'vuex-class';
import { Slide } from 'vue-burger-menu';
import { State } from '@/store/models';
import Logo from '@/components/common/logo/Logo.vue';
import NavbarLinks from '@/components/common/navbar/links/NavbarLinks.vue';
import DropdownMenu from '@/components/common/navbar/dropdown/DropdownMenu.vue';
import LanguagesAccount from '@/components/common/navbar/languages-account/button/LanguagesAccount.vue';
import LoginRegister from '@/components/common/navbar/login-register/LoginRegister.vue';
import ResponsiveMenu from '@/components/common/navbar/responsive/ResponsiveMenu.vue';
import ResponsiveLinks from '@/components/common/navbar/responsive/links/ResponsiveLinks.vue';
import isAuthPageHelper from '@/helpers/isAuthPageHelper';

@Component({
  components: {
    Slide,
    Logo,
    NavbarLinks,
    DropdownMenu,
    ResponsiveLinks,
    LanguagesAccount,
    LoginRegister,
    ResponsiveMenu,
  },
})

export default class Navbar extends Vue {
  @StateClass auth!: State['auth'];
  @StateClass misc!: State['misc'];
  @StateClass authModal!: State['authModal'];
  @Getter isUserLoggedIn!: State['isUserLoggedIn'];
  @Getter isModalOpen!: boolean;
  @Action openModal!: Function;

  isSliderOpen: boolean = false;

  @Watch('isModalOpen')
  onAuthActiveChange(isModalOpen): void {
    if (isModalOpen) {
      this.isSliderOpen = true;
      setTimeout((): void => {
        this.isSliderOpen = false;
      }, 150);
    }
  }

  get brand(): string {
    return require('../../../../whitelabel.config').name;
  }

  /* get myBrand(): string {
    return `${capitalize(this.$t('common.my') as string)} ${capitalize(this.brand)}`;
  } */

  get isLanding(): boolean {
    return this.$route.fullPath.startsWith('/landing');
  }

  get isAuthPage(): boolean {
    return isAuthPageHelper(this.$route);
  }
}
